import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { GeneralFunctionService } from '../services/general-function.service';

@Injectable()
export class AuthService {
  baseAPI = environment.baseAPI;
  token: string;

  constructor(private http: HttpClient,
              private router: Router,
              private allFunction : GeneralFunctionService,
              private cookieService: CookieService) {}

  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  signinUser(username: string, password: string) {
    // this.cookieService.delete('token'); 
    // this.logout();
    // your code for checking credentials and getting tokens for for signing in user
    const url = this.baseAPI + '/api/user-auth/login/';
    return this.http.post(url, {'username': username, 'password': password});
  }

  /** change password */
  changePassword(new_pass1:string, new_pass2:string){

    const data={
      "new_password1": new_pass1,
      "new_password2": new_pass2
    }
    const url = this.baseAPI + '/api/user-auth/password/change/';
    return this.http.post(url,data);
}
  logout() {
    // token user
    this.cookieService.delete('token','/'); 
    this.cookieService.delete('role','/');
    if(this.cookieService.check('token')){
      this.cookieService.delete('token'); 
      this.cookieService.delete('role'); 
    }

    if(!this.cookieService.check('token')){
      window.location.reload(); 
      console.log("no token") 
    }
    
    // role user
    // this.cookieService.delete('access');  
  }

  getToken() {
    if (this.cookieService.check('token')) {
    //  this.token = this.cookieService.get('token');
     this.token = this.allFunction.decryptFileForLocal(this.allFunction.environment.localEncriptKey,
       this.cookieService.get('token'))
    } else {
      this.token = null;
    }
    return this.token;
  }

  getPk() {
    if (this.cookieService.check('role')) {

    let role =  this.allFunction.decryptFileForLocal(this.allFunction.environment.localEncriptKey,
      this.cookieService.get('role'))

    return JSON.parse(role).pk
    } else {
     return null;
    }
  }

  getRole() {
    if (this.cookieService.check('role')) {

    let role =  this.allFunction.decryptFileForLocal(this.allFunction.environment.localEncriptKey,
      this.cookieService.get('role'))

    return JSON.parse(role).role
    } else {
     return null;
    }
  }
  
  isAuthenticated(): boolean {
    // here you can check if user is authenticated or not through his token
    if (!this.getToken()) {
      return false;
    } else {
      return true;
    } 
  }
  
  
  getUserRole(){
    const url = this.baseAPI + '/api/user-profile/role/';
    return this.http.get(url); 
  }

  resetPassword(user:number, pass:string){
    let data={
      "user":user,
      "pass":pass
    };

    const url = this.baseAPI + '/api/user-auth/reset-password/';
    return this.http.patch(url,data);
  }

  // getRole() {
  //   if (this.cookieService.check('access')) {
  //    return this.cookieService.get('access');
  //   } else {
  //    return null;
  //   }
  // }
}
