<div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card text-center">
          <div class="card-body">
  
            <div class="card-img overlap">
              <img 
                *ngIf="userProfile !=''"
                [src]="userProfile"
                alt="{{ userName }}"
                class="profile-img rounded-circle img-border gradient-summer"
              />
              <img 
              *ngIf="userProfile==''"
              src="../../assets/img/avartar.jpg"
              alt="avartar"
              class="profile-img rounded-circle img-border gradient-summer"
            />
            </div>
  
            <div class="card-block">
              <h4 class="card-title">{{ userName }}</h4>
              <p class="card-text">{{ userRole }}</p>
              <span class="text-center ">
                <span
                  class="fonticon-container"
                  style="cursor: pointer;"
                  (click)="openUserUploadModal()"
                >
                  <span class="fonticon-wrap">
                    <i class="ft-image" ngbTooltip="Change Profile Picture"></i>
                  </span>
                </span>
                <span
                  class="fonticon-container"
                  style="cursor: pointer;"
                  (click)="openUserPasswordModal()"
                >
                  <span class="fonticon-wrap">
                    <i class="ft-unlock" ngbTooltip="Change Password"></i>
                  </span>
                </span>
              </span>
            </div>
  
            <div class="card-block">
              <form
                style="background-color: #fff"
                [formGroup]="profileForm"
                class="form form-horizontal form-bordered ng-untouched ng-pristine ng-valid px-3"
              >
                <div class="form-body">
  
                  <div class="form-group row">
                    <label class="col-md-3 label-control" for="name">
                      {{ "Name" | translate }}
                    </label>
                    <div class="col-md-9">
                      <input
                        class="form-control"
                        id="name"
                        formControlName="name"
                        name="name"
                        placeholder="{{ 'Name' | translate }}"
                        type="text"
                      />
                    </div>
                  </div>
  
                  <div class="form-group row">
                    <label class="col-md-3 label-control" for="gender"
                      >{{ "Gender" | translate }}</label
                    >
                    <div [formGroup]="frmgender">
                      <input
                        id="gender"
                        type="radio"
                        value="male"
                        formControlName="gender"
                      />{{ "male" | translate }}
  
                      <input
                        id="gender"
                        type="radio"
                        value="female"
                        formControlName="gender"
                      />{{ "female" | translate }}
                    </div>
                  </div>
  
                  <div class="form-group row">
                    <label class="col-md-3 label-control" for="email">
                      {{ "Email" | translate }}
                    </label>
                    <div class="col-md-9">
                      <input
                        class="form-control"
                        id="email"
                        formControlName="email"
                        name="email"
                        placeholder="{{ 'Email' | translate }}"
                        type="text"
                      />
                    </div>
                  </div>
  
                  <div class="form-group row">
                    <label class="col-md-3 label-control" for="phone">
                     {{ "Phone Number" | translate }}
                    </label>
                    <div class="col-md-9">
                      <input
                        class="form-control"
                        id="phone"
                        formControlName="phone"
                        name="phone"
                        placeholder="{{ 'Phone Number' | translate }}"
                        type="text"
                      />
                      <p style="float: left;padding-top: 10px;color: #ccc;">Ex. 012123123</p>
                    </div>
                  </div>
  
                  <div class="form-group row">
                    <label class="col-md-3 label-control" for="dob">
                      {{ "Date Of Birth" | translate }}
                    </label>
                    <div class="col-md-9">
                      <div class="input-group">
                        <input
                          class="form-control"
                          placeholder="dd-mm-yyyy"
                          formControlName="popupModel"
                          ngbDatepicker
                          #d2="ngbDatepicker"
                          readonly
                        />
  
                        <div class="input-group-append">
                          <div class="input-group-text" (click)="d2.toggle()">
                            <i
                              class="fa fa-calendar"
                              style="cursor: pointer;"
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
  
                </div>
  
                <div class="form-action">
                  <button
                    class="btn btn-raised btn-raised btn-primary pull-right"
                    (click)="onUpdate()"
                    type="button"
                    style="margin-top:10px"
                  >
                    <i class="fa fa-edit"></i> {{ "Update" | translate }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!--Modal update user password -->
  <ng-template #user_password let-c="close" let-d="dismiss">
    <div class="modal-header">
      <div class="modal-title"><h4>{{ "User Change Password" | translate }}</h4></div>
      <button
        *ngIf="!changePassSuccess"
        type="button"
        class="close btnClose"
        aria-label="Close"
        (click)="d('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <div class="modal-body">
      <form class="form form-horizontal col-12" [formGroup]="FormUserPass">
        <div class="form-body">
          <div class="form-group row">
            <label for="old_password" class="col-md-3 label-control">
              {{ "Old Password" | translate }}:
            </label>
            <div class="col-md-9">
              <input
                type="password"
                id="old_password"
                formControlName="old_password"
                class="form-control"
                name="old_password"
                minlength="6"
                placeholder=" {{ 'please input old password' | translate }} ..."
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="new_password" class="col-md-3 label-control">
              {{ "New Password" | translate }}:
            </label>
            <div class="col-md-9">
              <input
                type="password"
                id="new_password"
                formControlName="new_password"
                (keyup)="validateMatchPass()"
                minlength="6"
                class="form-control"
                name="new_password"
                placeholder="{{ 'please input new password' | translate }}..."
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="confirm_new_password" class="col-md-3 label-control">
              {{ "Confirm New Password" | translate }}:
            </label>
            <div class="col-md-9">
              <input
                type="password"
                id="confirm_new_password"
                formControlName="confirm_new_password"
                (keyup)="validateMatchPass()"
                minlength="6"
                class="form-control"
                name="confirm_new_password"
                placeholder="{{ 'please input confirm new password' | translate }}..."
              /><br />
              <p class="note"> {{ "Noted : Password at less 6 charaters" | translate }}</p>
            </div>
          </div>
  
          <div class="form-group row">
            <div class="col-md-9 offset-md-3">
              <div
                *ngIf="!validateMatchPass()"
                class="alert alert-danger"
                role="alert"
              >
                {{ "New Password And Confirm Password Are Not Match" | translate }} !
              </div>
              <div *ngIf="valid_old_pass" class="alert alert-danger" role="alert">
                {{ "Old Password Not Valid" | translate }} !
              </div>
              <div
                *ngIf="valid_pass_weak"
                class="alert alert-danger"
                role="alert"
              >
                {{ "New Password should complex" | translate }} !
              </div>
              <div
                *ngIf="changePassSuccess"
                class="alert alert-success"
                role="alert"
              >
                {{ "Change Password Success. Please" | translate }}
                <a style="color:#0515e4" (click)="loginAgain()"> {{ "Login Agin" | translate }} </a>, {{ "Auto to login" | translate }} :
                {{ timer }}
              </div>
            </div>
          </div>
        </div>
        <button
          *ngIf="!changePassSuccess"
          [disabled]="!FormUserPass.valid || !validateMatchPass()"
          (click)="updateUserPassword()"
          class="btn btn-raised btn-primary pull-right"
        >
          <span><i class="fa fa-edit"></i> {{ "Change Password" | translate }}</span>
        </button>
      </form>
    </div>
  </ng-template>
  <!--Modal update user password -->
  <!--Modal upload profile -->
  <ng-template #user_upload let-c="close" let-d="dismiss">
    <div class="modal-header">
      <div class="modal-title"><h4>{{ "Upload New Profile" | translate }}</h4></div>
      <button
        type="button"
        class="close btnClose"
        aria-label="Close"
        (click)="d('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <div class="modal-body">
      <form class="form form-horizontal col-12">
        <div class="form-body">
          <div class="form-group row">
            <label for="profile_pic" class="col-md-3 label-control"
              >{{ "Upload New Profile" | translate }}:</label
            >
            <div class="col-md-9">
              <div class="lds-ellipsis" *ngIf="processing">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
  
              <input
                id="profile_pic"
                *ngIf="!processing"
                type="file"
                accept="image/*"
                name="profile_pic"
                class="form-control"
                (change)="onFileChange($event)"
                required
              /><br />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-9 offset-md-3">
              <div *ngIf="uploadErr" class="alert alert-danger" role="alert">
               {{ "File Upload Error" | translate }} !
              </div>
              <div *ngIf="updateErr" class="alert alert-danger" role="alert">
                {{ "Update Failed" | translate }} !
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </ng-template>
  <!--Modal upload profile  -->
  