import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse, HttpEventType } from '@angular/common/http';
import { AuthService } from '../../shared/auth/auth.service';

@Injectable()


export class MediaService {

  // declaration
  baseAPI = environment.baseAPI;
  constructor(private http: HttpClient, private authService: AuthService) {
  }
  /** get list folder  */
  getListFolder() {
    const url = this.baseAPI + '/api/file-manager/folder/';
    return this.http.get(url);
  }

  /** get list image gallery with pagination */
  getImageGallery(page: number) {
    // const url = this.baseAPI + '/api/file-manager/folder/' + folder + '/image/';
    let url = '';
    if (page === 1) {
        url = this.baseAPI + '/api/file-manager/image/';
    } else {
        url = this.baseAPI + '/api/file-manager/image/?page=' + page ;
    }
    return this.http.get(url);
  }

  /** upload image with base64 file  */
  // uploadImage(name, file) {
    // constan url api
  //     const url = this.baseAPI + '/api/file-manager/folder/' + environment.folder_image_id + '/image/';
  //     const req = new HttpRequest('POST', url, {'name': name, 'file': file}, {
  //       reportProgress: true
  //     }); 
  //     return this.http.request(req);
  // }

  /** upload image */
  uploadImage(obj:any){
    const url = this.baseAPI + '/api/file-manager/folder/' + environment.folder_image_id + '/image/';
         const req = new HttpRequest('POST', url, obj, {
        reportProgress: true
      }); 
      return this.http.request(req);
    // return this.http.post(url, obj);
  }

  /** delete image by id */
  deleteImage(id: number) {
    const url = this.baseAPI + '/api/file-manager/image/' + id + '/';
    return this.http.delete(url);
  }

  /** upload video base64 file  */
  // uploadVideo(name, file) {
    // constan url api
  //     const url = this.baseAPI + '/api/file-manager/folder/' + environment.folder_video_id + '/video/';
  //     const req = new HttpRequest('POST', url, {'name': name, 'file': file}, {
  //       reportProgress: true
  //     });
  //     return this.http.request(req);
  // }

  /** upload video base64 file  */
  uploadVideo(obj:any){
    const url = this.baseAPI + '/api/file-manager/folder/' + environment.folder_video_id + '/video/'; 
      const req = new HttpRequest('POST', url,obj, {
      reportProgress: true
      });
      return this.http.request(req);
    // return this.http.post(url, obj);
  }

  /** get list video with pagination */
  getVideoGallery(page: number) {

    let url = '';
    if (page === 1) {
        url = this.baseAPI + '/api/file-manager/video/';
    } else {
        url = this.baseAPI + '/api/file-manager/video/?page=' + page ;
    }
    return this.http.get(url);
  }
  
  /** delete video by id */
  deleteVideo(id: number) {
      const url = this.baseAPI + '/api/file-manager/video/' + id + '/';
      return this.http.delete(url);
  }

  /** replace image upload */
  replaceUploadImage(form_data:any){
    // constan url api
    const url = this.baseAPI + '/api/file-manager/replace_image/';
    return this.http.post(url,form_data);
  }
}
