// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  baseAPI: 'https://api.domreynews.com',  //Live
  //baseAPI : 'http://54.254.72.126:8585',  //Test
  localEncriptKey : 'GBS@2015', 
  folder_image_id: 3,
  //base_token:"5165fb94ef9dc12904f4d90ca0103b2512d85b72",
  folder_video_id: 1,
};
