import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ContentPagesRoutingModule } from './login-routing.module';
import { LoginPageComponent } from './login.component';



@NgModule({
    imports: [
        CommonModule,
        ContentPagesRoutingModule,
        FormsModule,
        NgbModule
    ],
    declarations: [
        LoginPageComponent
    ]
})
export class LoginModule { }
