import { Component, ViewChild , OnInit} from '@angular/core';
import { NgForm } from '@angular/forms';
import { debounceTime} from 'rxjs/operators'; 
import { AuthService } from '../shared/auth/auth.service'
import { Router, ActivatedRoute } from '@angular/router'; 
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'environments/environment';
import { GeneralFunctionService } from 'app/shared/services/general-function.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginPageComponent implements OnInit {
 
    @ViewChild('f') loginForm: NgForm;
    username = '';
    password = '';
    staticAlertClosed = true;
    msg ='';
    loading = false ;
    constructor(private router: Router,
                private route: ActivatedRoute,
                private auth: AuthService, 
                private allFunction : GeneralFunctionService,
                private cookieService: CookieService ) {  }

    ngOnInit(): void {
        this.checkToken();
        // Auto close alert timer
        // setTimeout(() => this.staticAlertClosed = true, 100);
    }
    //Check If Have Token
    checkToken(){
        if (this.cookieService.check('token')) {
            this.router.navigate(['/dashboard']);      
        }
    }
    // On submit button click 
    onSubmit() {
        this.loading = true;
        this.username = this.loginForm.value.username ;
        this.password = this.loginForm.value.password ;
        
        this.auth.signinUser(this.username, this.password).subscribe(
         (data) => {
           // check response key data is exist
           if (data['key']) {
                console.log(data);
             // store user token for 6 hours
                let tmpToken = this.allFunction.encryptFileForLocal(
                        environment.localEncriptKey,data['key'])
                this.cookieService.set('token', tmpToken, 0.25);
            //  this.cookieService.set('token', data['key'], 0.25);
             this.router.navigate(['/dashboard']);            
           }
         },
         (error) => {
             this.msg = "Please Check Username and Password Again !";
             this.staticAlertClosed = false;
             this.loading =  false; }
         );
     }

    // On Forgot password link click
    onForgotPassword() {
        this.router.navigate(['forgotpassword'], { relativeTo: this.route.parent });
    }

    // On registration link click
    onRegister() {
        this.router.navigate(['register'], { relativeTo: this.route.parent });
    }
    
    // On reset event
    resetForm() {
        this.loginForm.reset();
    }

}
