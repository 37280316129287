import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot,Router,ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
              private authService: AuthService,   
              private router: Router, 
              private route: ActivatedRoute,
    
    ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
       
    if ( this.authService.isAuthenticated() ) {
      return true;
    }
    // navigate to login page
    this.router.navigate(['/login']);
    // you can save redirect url so after authing we can move them back to the page they requested
    return false;
  } 
}
