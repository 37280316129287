import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthService } from "../../shared/auth/auth.service";

@Injectable()
export class UserService {

  // declaration
  baseAPI = environment.baseAPI;
  detailData: any;
  constructor(
      private http: HttpClient,
      private authService: AuthService) {}

  /** get base url */
  getBaseURL() {
    return environment.baseAPI;
  }

  /** list user */
  getListUser(page: number,name:string, status:string, role: string) {
    
    let url = "";

    if (role =='all') {
        role = '';
    }
    
    url = this.baseAPI + "/api/user-profile/list/?search="+ name +"&role=" + role +"&user__is_active="+ status +"&page=" + page;
    return this.http.get(url);
  }

  /** detail data store in laocal array */
  detailUser(user:any){
      this.detailData = user;
  }

  /** get detail data from local array */
  getDetailUser(){
      return this.detailData;
  }

  /** create user */
  addUser(user:any){ 
    let headers = new HttpHeaders({
        'Content-Type': 'application/json'});
    let options = { headers: headers };
    let url = this.baseAPI + "/api/user-auth/signup/";
    return this.http.post(url, user, options);
  }

  /** disable user status */
  statusUser(user_id:number){
    let url = this.baseAPI + "/api/user-auth/user-status/"+ user_id +"/";
    return this.http.patch(url,'');
  }
  
  /** get user profile */
  getUserProfile(){
    const url = this.baseAPI + "/api/user-profile/";
    return this.http.get(url);
    
  }

  /** update profile information */
  updateUserProfile(data:any){
    let url = this.baseAPI + "/api/user-profile/";
    return this.http.put(url,data);
  }

  /** updare user role */
  updateUserRole(user_id:number, role:any){
    let url = this.baseAPI + "/api/user-auth/user-role/"+ user_id +"/";
    return this.http.patch(url, role);
  }
  
  /** upload profile */
  uploadProfile(profile:any,user_id:number){
    const url =this.baseAPI + "/api/user-profile/manage/"+user_id+'/';
    return this.http.patch(url,profile);
  }  
  /** get user role */
  getUserRole(){
      let url = this.baseAPI + "/api/user-profile/role/";
      return this.http.get(url);
  }

  /** update user profile */
  updateUserProfileAvatar(avatar:any){
    const url =this.baseAPI + "/api/user-profile/";
    return this.http.patch(url, avatar);
  }

  /** get total user count */
  totalUserRegister(){
    const url =this.baseAPI + "/api/user-profile/total_user/";
    return this.http.get(url);
  }
  
}