import { Routes, RouterModule } from '@angular/router';
import { ProfileComponent } from '../../profile/profile.component';

//Route for content layout with sidebar, navbar and footer
export const Full_ROUTES: Routes = [
  
  {
    path: 'dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule) 
  },
  {
    path: 'category',
    loadChildren: () => import('../../category/category.module').then(m => m.CategoryModule)
  }, 
  {
    path: 'menu',
    loadChildren: () => import('../../menu/menu.module').then(m => m.MenuModule) 
  },
  {
    path: 'user',
    loadChildren: () => import('../../user/user.module').then(m => m.UserModule)
  },   
  {
    path: 'media',
    loadChildren: () => import('../../media/media.module').then(m => m.MediaModule)
  },
  {
    path: 'news',
    loadChildren: () => import('../../news/news.module').then(m => m.NewsModule)
  },
  {
    path: 'video-news',
    loadChildren: () => import('../../video-news/video-news.module').then(m => m.VideoNewsModule)
  },
  {
    path: 'ads-banner',
    loadChildren: () => import('../../ads-banner/banner.module').then(m => m.BannerModule) 
  },
  {
    path: 'news-announce',
    loadChildren: () => import('../../news-announce/announce.module').then(m => m.NewsAnnounceModule) 
  },
  {
    path: 'notification',
    loadChildren: () => import('../../notification/notification.module').then(m => m.NotificationModule)
  },
  {
    path: 'changelog',
    loadChildren: () => import('../../changelog/changelog.module').then(m => m.ChangeLogModule)
  },
  {
    path: 'full-layout',
    loadChildren: () => import('../../pages/full-layout-page/full-pages.module').then(m => m.FullPagesModule)
  },
  {
    path: 'profile',
    component: ProfileComponent
  },

    
  // if no authentication return to login component -token
  { path: '**', redirectTo: '/login', pathMatch: 'full'},
];
