<!--Login Page Starts-->
<section id="login" style="background-image: linear-gradient(207.74deg,#18489E 0%,#18489E 100%);">
    <div class="container-fluid">
        <div class="row full-height-vh">
            <div class="col-12 d-flex align-items-center justify-content-center">
                <div class="card gradient-indigo-purple text-center width-400" style="position: relative;z-index: 99;padding-top: 35px;">
                    <div class="card-img">
                        <img alt="element 06" class="mb-1" src="assets/img/logos/logo.png">
                    </div>
                    
                    <div class="card-body">
                        <div class="card-block">
                            <!-- <h2 style="color: #009DA0;
                                       font-weight: 400;">
                                       Login</h2> -->
                            <div class="sk-cube-grid" [ngStyle]="{'display' : loading?'inline-block':'none'}">
                                <div class="sk-cube sk-cube1"></div>
                                <div class="sk-cube sk-cube2"></div>
                                <div class="sk-cube sk-cube3"></div>
                                <div class="sk-cube sk-cube4"></div>
                                <div class="sk-cube sk-cube5"></div>
                                <div class="sk-cube sk-cube6"></div>
                                <div class="sk-cube sk-cube7"></div>
                                <div class="sk-cube sk-cube8"></div>
                                <div class="sk-cube sk-cube9"></div>
                            </div>

                            <ngb-alert *ngIf="!staticAlertClosed" (close)="staticAlertClosed = true">
                                {{msg}}
                            </ngb-alert>

                            <form #f="ngForm">
                                <div class="form-group">
                                    <div class="col-md-12">
                                        <input type="text" class="form-control" name="username" placeholder="Username"
                                            ngModel required>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="col-md-12">
                                        <input type="password" class="form-control" name="password" placeholder="Password"
                                            ngModel required>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-md-12">
                                        <button (click)="onSubmit()" [disabled]="!f.valid || loading" type="submit" class="btn btn-primary btn-block btn-raised">Login</button>
                                        <button (click)="resetForm()" [disabled]="loading" class="btn btn-secondary btn-block btn-raised">Reset</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Login Page Ends-->