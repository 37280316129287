import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [

    { path: '/dashboard', title: 'Dashboard', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/user/list-user', title: 'User', icon: 'ft-user', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [ ]
    },
    {
        path: '/media/gallery', title: 'Media', icon: 'ft-file', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [ ]
    },
    {
        path: '/news/list-news', title: 'News', icon: 'ft-file-text', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [ ]
    },
    {
        path: '/category/list-category', title: 'Category', icon: 'ft-package', class: '', badge: '', badgeClass: '', isExternalLink: false,submenu: [ ]
    },
    {
        path: '/menu/list-menu', title: 'Menu', icon: 'ft-menu', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [ ]
    },
    {
        path: '/ads-banner/list-banner', title: 'ADS Banner', icon: 'ft-menu', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [ ]
    }, 
    {  
        path: '/video-news/list-video-news', title: 'Video News', icon: 'ft-video', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {  
        path: '/notification/send-notification', title: 'Send Notification', icon: 'ft-message-square', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
];

// route for role editor
export const EDITOR_ROUTES: RouteInfo[] = [

    {
        path: '/dashboard', title: 'Dashboard', icon: 'ft-home',
        class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
   
    {
        path: '/media/gallery', title: 'Media', icon: 'ft-file',
        class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
    },
    {
        path: '/news/list-news', title: 'News', icon: 'ft-file-text',
        class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
    },
    {  
        path: '/video-news/list-video-news', title: 'Video News', icon: 'ft-video', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {  
        path: '/notification/send-notification', title: 'Send Notification', icon: 'ft-message-square', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },

];
