import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { AuthService} from '../../shared/auth/auth.service' 
import { GeneralFunctionService } from '../services/general-function.service';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
              private cookieService: CookieService,
              private router: Router,
              private allFunction : GeneralFunctionService,
              private auth: AuthService) {}

  intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq ;
    if (this.cookieService.check('token')) {
      // console.log('have token');
      authReq = req.clone({
        headers: req.headers.set('Authorization', 'Token ' + 
        this.allFunction.decryptFileForLocal(this.allFunction.environment.localEncriptKey,
          this.cookieService.get('token')))
        // this.cookieService.get('token'))
      });
   } else {
  //    console.log('no token');
      authReq = req.clone({
        headers: req.headers.set('Content-Type', 'application/json')
      });
      this.router.navigate(['/login']);
   }
   return next.handle(authReq);
}
}
