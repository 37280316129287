import { Component, OnInit, ViewContainerRef, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from '../shared/services/dateformat';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../shared/services/user.service';
import { AuthService } from "../shared/auth/auth.service";
import {NgbDateStruct, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
// import { ToastsManager } from 'ng2-toastr/ng2-toastr';
import { Router } from "@angular/router";
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [
    {provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter}
   ] // that service use to change format date to dd-mm-yyyy 
})
export class ProfileComponent implements OnInit {

    @ViewChild('user_password') private UserPasswordModal: TemplateRef<any>;
    @ViewChild('user_upload') private UserUploadModal: TemplateRef<any>;
    modal: any;
    

    profileForm =new FormGroup({
        name: new FormControl('',Validators.required),
        email: new FormControl('',[Validators.required, Validators.email]),
        phone: new FormControl('',[Validators.required, Validators.minLength(6)]),
        popupModel:new FormControl('',Validators.required)
    });

    FormUserPass = new FormGroup({
        old_password:new  FormControl('',[Validators.required, Validators.minLength(6)]),
        new_password: new  FormControl('',[Validators.required, Validators.minLength(6)]),
        confirm_new_password: new FormControl('',[Validators.required, Validators.minLength(6)])
    });

   frmgender = new FormGroup({
     gender: new FormControl('',Validators.required),
   });
   
   profileUser:any = {};
   authName = '';
   userName ='';
   userRole ='';
   userProfile='';
   timer = 0;
   timerId:any;
   changePassSuccess = false;
   valid_old_pass = false;
   valid_pass_weak = false;
   popupModel:NgbDateStruct;
   date: {year: number, month: number};
   processing = false;
   uploadErr = false;
   updateErr = false;

   constructor( 
        private modalService: NgbModal,
        private userService:UserService,
        private authService:AuthService,
        // private toastr: ToastsManager,
        private router: Router,
        private vRef: ViewContainerRef
       ) {
        // set root component for display toast message
        // this.toastr.setRootViewContainerRef(vRef);
       }
 
  ngOnInit() {

    this.userRole = this.authService.getRole();
    this.userService.getUserProfile().subscribe(
        
        data =>{
            this.profileUser = data;
            // cover phone +855 to 0
            let phone = this.profileUser.phone_number;
            let phone_tmp;
            if(this.profileUser.phone_number != undefined){
                phone_tmp = phone.replace('+855','0');
            }else{
                phone_tmp = '';
            }
            if (this.profileUser.avatar != null){
                this.userProfile= this.profileUser.avatar;
            }
            this.userName = this.profileUser.name;
            this.profileForm.controls.name.setValue(this.profileUser.name);
            this.frmgender.controls.gender.setValue(this.profileUser.gender);
            this.profileForm.controls.email.setValue(this.profileUser.email);
            this.profileForm.controls.phone.setValue(phone_tmp);
            this.profileForm.controls.popupModel.setValue(this.formatDate(this.profileUser.date_of_birth));
            this.authName = this.profileUser.user.username;
        },
        err =>{
            console.log(err);
        }
    );
  }
  
  // covert date time to object use with date picker angular
  formatDate (input) {
    console.log(input);
    if(input != null){
        var datePart = input.match(/\d+/g),
        year = datePart[0],
        month = datePart[1],
        day = datePart[2];
        let data = {
            'day':parseInt(day, 10),
            'month':parseInt(month, 10),
            'year':parseInt(year, 10)
        } 
        return data;
    }else{
        return '';
    }
  }

  //Go To Log In when change password
  loginAgain(){
      this.authService.logout();
      this.router.navigate(["/login"]);
  }

  // covert date string for display year-month-day
  formatDateToString(date:any){
    if(date != null){
        let day = date.day;
        let month = date.month;
        let year = date.year;

        return year+ "-" + month + "-" + day;
    }else{
        return '';
    }
    

  }


  // update user profile information
  onUpdate() {
    let phone = this.profileForm.controls.phone.value;
    let data ={
        'name':this.profileForm.controls.name.value,
        'gender':this.frmgender.controls.gender.value,
        'email': this.profileForm.controls.email.value,
        'phone_number': phone.replace('0','+855'),
        'date_of_birth': this.formatDateToString(this.profileForm.controls.popupModel.value)
    }

    this.userService.updateUserProfile(data).subscribe(
        data=>{
            //this.toastr.success('Update Success'); 
        },
        err=>{
            console.log(err);
            // this.toastr.error('Update Failed');
        }
    )
  }

  //open user password modal
  openUserPasswordModal(){
    // this.timer = 0;
    this.modal = this.modalService.open(this.UserPasswordModal, { size: 'lg', backdrop: 'static' });
  }
  
  //update user password 
  updateUserPassword(){

    this.valid_old_pass = false;
    this.valid_pass_weak = false;
    let username = this.authName;
    let old_password = this.FormUserPass.controls.old_password.value;
    let new_password = this.FormUserPass.controls.new_password.value;
    let confirm_new_password = this.FormUserPass.controls.confirm_new_password.value;
    this.authService.signinUser(username, old_password).subscribe(
        data=>{
            this.authService.changePassword(new_password, confirm_new_password).subscribe(
                data =>{
                    console.log(data);
                    this.changePassSuccess = true;
                    this.timerId =  setInterval(()=>{
                        this.timer += 1;
                        if(this.timer === 5){
                            this.timer = 0;
                            clearInterval(this.timerId);
                            this.modal.close();
                            this.router.navigate(["/login"]);
                        } 
                    },1000);
                },
                err =>{
                    this.valid_pass_weak = true
                    console.log(err);
                }
            );
  
        },
        err=>{
            this.valid_old_pass = true;
            console.log("User Old Password Not Valid !!");
            console.log(err);
        }
    );
  }

  //open user upload modal
  openUserUploadModal(){
    this.modal = this.modalService.open(this.UserUploadModal, { size: 'lg', backdrop: 'static' });
  }

  // upload image profile 
  onFileChange(event) {
        if (event.target.files[0] != undefined){
            this.processing = true;
            this.uploadErr = false;
            this.updateErr = false;
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('avatar', file);
            
            this.userService.uploadProfile(formData,this.profileUser.id).subscribe(
                data =>{
                    this.processing = false;
                    this.userProfile =  data['avatar'];
                    this.modal.close();
                },
                err=>{
                    this.uploadErr = true;
                    console.log(err);
                }
            );
        }
  }
      
  // validate match password
  validateMatchPass(){
    let pass1 = this.FormUserPass.controls.new_password.value;
    let pass2 = this.FormUserPass.controls.confirm_new_password.value;
    return pass1 === pass2 ? true : false; 
  }
}
